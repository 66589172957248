
import { defineComponent } from 'vue'
import YearPicker from '@/views/Payment/components/YearPicker.vue'
import usePaymentTracking from '@/hooks/payment/Mgmt/PaymentTracking/usePaymentTracking'
import moment from 'moment'
import { elementSize } from '@/utils'
import { toThousands } from "@/utils/approve";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import ApproveSteps from "@/views/Payment/components/ApproveSteps.vue";
import CollapseWrapper from "@/views/Payment/components/CollapseWrapper.vue";

export default defineComponent({
  name: "paymentTracking",
  components: {
    YearPicker,
    ApproveSteps,
    CollapseWrapper
  },
  props: {},
  setup() {
    const {
      searchParams,
      columnsDealer,
      columnsMBAFCandMBLC,
      tableDataDealer,
      tableDataMBAFCandMBLC,
      doSearch,
      downloadDealerPayoutCodeFile,
      downloadAFCPayoutCodeFile,
      approveVisible,
      approveClick,
      approveList
    } = usePaymentTracking()

    doSearch()

    const tableSize = elementSize('.ant-table-wrapper')

    return {
      moment,
      searchParams,
      tableDataDealer,
      tableDataMBAFCandMBLC,
      columnsDealer,
      columnsMBAFCandMBLC,
      doSearch,
      tableSize,
      toThousands,
      toThousandsAndTwoDecimal,
      downloadDealerPayoutCodeFile,
      downloadAFCPayoutCodeFile,
      approveVisible,
      approveClick,
      approveList
    }
  }
});
