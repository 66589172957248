import { reactive, watch, ref } from 'vue'
import moment from 'moment'
import { Moment } from 'moment';
import { ApprovealTableData } from "@/views/Payment/Mgmt/types"
import * as API from "@/API/payment"
import { useStore } from 'vuex';
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { ApproveData } from '@/views/Payment/types'
import { message } from 'ant-design-vue';

type SearchParams = {
  payoutYear: Moment;
  payoutMonth: Moment | null;
  payoutCode: string;
}

const usePaymentTracking = () => {
  const searchParams = reactive<SearchParams>({
    payoutYear: moment(),
    payoutMonth: null,
    payoutCode: ''
  })

  watch(() => searchParams.payoutYear, (value) => {
    if (value) {
      searchParams.payoutMonth?.year(value.year())
    }
  })

  const columnsDealer = [
    { title: "Payout Round", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' },width: 180},
    { title: "Payout Code", dataIndex: "payoutCode",slots: { customRender: 'payoutCode' }, key: "payoutCode", width: 180},
    { title: "Program No.", dataIndex: "programNo",slots: { customRender: 'programNo' }, key: "programNo", width: 100, align: 'right'},
    { title: "Dealer No.", dataIndex: "dealerNo",slots: { customRender: 'dealerNo' }, key: "dealerNo", width: 100, align: 'right'},
    { title: "Payout Amount w/o VAT", dataIndex: "payoutAmountWVoat",slots: { customRender: 'payoutAmountWVoat' },  width: 180, align: 'right'},
    { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",slots: { customRender: 'payoutAmountWVat' },  width: 160, align: 'right'},
    { title: 'Payout Status', dataIndex: "payoutStatus", width: 100, align: 'center'},
    { title: "Submission Date", dataIndex: "submissionDate", width: 120, key: "retailDate", align: 'center'},
    { title: "Approval Path", key: "operation", fixed: 'right',  align: 'left', width: 100, slots: { customRender: "action"}}
  ]
  const columnsMBAFCandMBLC = [
    { title: "Payout Round", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' }, width: 130},
    { title: "Payout Code", dataIndex: "payoutCode", slots: { customRender: 'payoutCode' },width: 200},
    { title: "Program No.", dataIndex: "programNo",slots: { customRender: 'programNo' }, width: 100, align: 'right' },
    { title: "VIN No.", dataIndex: "vinNo",slots: { customRender: 'vinNo' },width: 100, align: 'right' },
    { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",slots: { customRender: 'payoutAmountWVat' },  width: 160, align: 'right'},
    { title: 'Payout Status', dataIndex: "payoutStatus", width: 100, align: 'center'},
    { title: "Submission Date", dataIndex: "submissionDate", width: 120, key: "retailDate", align: 'center'},
    { title: "Approval Path", key: "operation", fixed: 'right',  align: 'left', width: 100, slots: { customRender: "action"}}
  ]

  const tableDataDealer = ref<ApprovealTableData[]>()
  const tableDataMBAFCandMBLC = ref<ApprovealTableData[]>()

  const store = useStore()

  const doSearch = () => {
    const params = {
      year: searchParams.payoutYear.format('YYYY'),
      month: searchParams.payoutMonth ? searchParams.payoutMonth.format('M') : '',
      payoutCode: searchParams.payoutCode,
      pageNumber: 1,
      pageSize: 10000,
      userId: store.state.user.usrid,
      payoutRound: '',
      typeList: []
    }
    // params.status = 'DEALER'
    // API.getApprovealPaymentTrackingTable(params).then((res: {content: ApprovealTableData[]}) => {
    //   tableDataDealer.value = res.content
    // })
    API.getDealerTrackingList(params).then((res: {content: ApprovealTableData[]}) => {
      tableDataDealer.value = res.content
    })
    // params.status = 'AFCANDLC'
    // API.getApprovealPaymentTrackingTable(params).then((res: {content: ApprovealTableData[]}) => {
    //   tableDataMBAFCandMBLC.value = res.content
    // })
    API.getFinanceTrackingList(params).then((res: {content: ApprovealTableData[]}) => {
      tableDataMBAFCandMBLC.value = res.content
    })
  }

  const downloadDealerPayoutCodeFile = (payoutCode: string) => {
    API.downloadPendingDealerPayoutCodeFile(payoutCode).then((res: any) => {
      downloadFromStream(res)
    })
  }

  const downloadAFCPayoutCodeFile = (payoutCode: string) => {
    API.downloadPendingAFCPayoutCodeFile(payoutCode).then((res: any) => {
      downloadFromStream(res)
    })
  }

  const approveList = ref<ApproveData[]>()
  const approveVisible = ref(false)
  const approveClick = (record: any, type: string) => {
    approveList.value = []
    // API.getApprovalPackage(record.id).then((res: any) => {
    //   if (res.code === '0') {
    //     approveList.value = res.data.nodeList
    //     approveVisible.value = true
    //   } else {
    //     message.error(res.message)
    //   }
    // })
    const params = {
      approvalType: "PayoutApproval",
      payoutCodeId: record.id,
      payoutType: type
    }
    API.getApprovalPackageByCreated(params).then((res: any) => {
        if (res.code === '0') {
            approveList.value = res.data.map((item: any) => item.nodeList)
            approveVisible.value = true
        } else {
            message.error(res.message)
        }
    })
  }

  return {
    searchParams,
    columnsDealer,
    columnsMBAFCandMBLC,
    tableDataDealer,
    tableDataMBAFCandMBLC,
    doSearch,
    downloadDealerPayoutCodeFile,
    downloadAFCPayoutCodeFile,
    approveVisible,
    approveClick,
    approveList
  }
}

export default usePaymentTracking